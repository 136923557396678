import React, { FC } from 'react'
import { FUSE_URL } from './config'
import { CodeBlock } from '../../components/codeBlock/CodeBlock'
import styled from 'styled-components'

export const InstructionCodeBlockSC = styled(CodeBlock)`
  text-align: center;
  padding: 0 30px 0 0;
  margin: 0 10px -3px 10px;
  min-width: 100px;
  display: inline-block;
  border-width: 0;
`

export const FuseCustomInstructions: FC = () => {
  return (
    <>
      <h2 style={{ marginTop: '50px' }}>Your Tailored Instructions</h2>
      <p>Fuse analyzes your queries and applies tailored AI instructions to provide accurate, relevant responses.</p>
      <p>
        In addition to the built-in instructions, you can create your own to match your specific workflows and daily
        tasks — ensuring Fuse assists you exactly the way you need!
      </p>
      <p>
        <br />
        <strong>Get the most out of Fuse.</strong> Start by creating your own instructions using{' '}
        <a href={FUSE_URL}>Fuse CLI</a>
        <InstructionCodeBlockSC light={true}>fuse instructions new</InstructionCodeBlockSC>
      </p>
    </>
  )
}
