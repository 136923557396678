import React, { FC, ReactNode } from 'react'
import { usePageTitle } from '../../utils/customeHooks/usePageTitle'
import { FlexibleLayout } from '../../layouts/flexible/FlexibleLayout'
import { Container } from '../../layouts/container/Container'
import { PageHeader } from '../../components/pageHeader/PageHeader'
import { ConnectedMachines } from './ConnectedMachines'
import { Button, Col, Row, Space, Typography } from 'antd'
import { FUSE_MORE_TOKENS_URL } from './config'
import { CardSC } from './styles'
import { usePostHog } from 'posthog-js/react'
import { FeatureFlag } from '../../utils/posthog'
import { FuseCustomInstructions } from './FuseCustomInstructions'

const data: { title: string; description: ReactNode }[] = [
  {
    title: 'What is the AI Token Limit?',
    description: (
      <>
        <p>
          The AI token limit refers to the maximum number of tokens that can be utilized for AI requests within each
          calendar month. Based on our statistics, an average prompt generally consumes around 5,000 tokens.
        </p>
        <p>The token usage is recalculated every few minutes.</p>
      </>
    ),
  },
  {
    title: 'Is the Organization-Scoped AI Token Limit Applicable?',
    description: (
      <>
        <p>
          Each installation of Botkube Fuse operates under its own distinct AI token limit. This structure is designed
          to allow users to explore the latest AI features without concerns about token usage.
        </p>
        <p>
          The Botkube Cloud organization AI token limit applies specifically to the Botkube AI Assistant plugin
          available on various chat platforms.
        </p>
      </>
    ),
  },
  {
    title: 'How Does Fuse Differ from the Botkube AI Assistant?',
    description: (
      <>
        <p>
          The Botkube AI Assistant is a plugin integrated into Botkube within a Kubernetes cluster, facilitating the
          identification, diagnosis, and troubleshooting of issues directly from your preferred chat platforms.
        </p>
        <p>
          In contrast, Fuse serves as a terminal tool that incorporates a more advanced AI assistant, offering
          capabilities such as local filesystem access and code execution support.
        </p>
        <p>
          While these assistants currently function as separate tools with distinct features, we plan to integrate many
          of Fuse&apos;s innovative functionalities into chat platforms in the future.
        </p>
      </>
    ),
  },
]

export const FusePage: FC = () => {
  usePageTitle('Fuse')

  const posthog = usePostHog()
  if (!posthog.isFeatureEnabled(FeatureFlag.DISPLAY_FUSE)) {
    return null
  }

  return (
    <FlexibleLayout>
      <PageHeader
        title={'Meet Fuse, Your All-in-One Platform Engineering Copilot.'}
        description='Botkube Fuse brings together information from all your essential tools into single terminal interface,
offering real-time updates and expert insights with the help of AI.'
        extra={
          <Space
            wrap={true}
            style={{ marginTop: '20px' }}
          >
            <Button
              type='primary'
              href={'https://botkube.io/fuse'}
            >
              Read more
            </Button>
            <Button
              href={FUSE_MORE_TOKENS_URL}
              type='default'
            >
              Get more AI tokens
            </Button>
          </Space>
        }
      />
      <Container className='mt-20'>
        <FuseCustomInstructions />
        <ConnectedMachines />
        <h2>Frequently Asked Questions</h2>
        <Row
          gutter={[16, 16]}
          className={'mb-40'}
        >
          {data.map(item => {
            return (
              <Col
                key={item.title}
                className='gutter-row'
                xs={24}
                sm={24}
                md={8}
              >
                <CardSC
                  title={
                    <>
                      <Typography.Text ellipsis={{ tooltip: item.title }}>{item.title}</Typography.Text>
                    </>
                  }
                >
                  {item.description}
                </CardSC>
              </Col>
            )
          })}
        </Row>
      </Container>
    </FlexibleLayout>
  )
}
